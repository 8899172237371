import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import '../assets/style/style.scss';
import '../assets/style/magnific-popup.scss';

import slideImg from '../assets/images/slide2.jpg';

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <script src="https://d33wubrfki0l68.cloudfront.net/js/eaa613478579114c6413457f04df3a7c3b8f2d7c/assets/main-ce9573b43b4d9d6082f24cc39dcb21f0ea3b4afb7bc38e464d384cad2ffe7296.js"></script>
        {/* <script src="/scripts/masonry.pkgd.min.js"></script>
        <script src="/scripts/imagesloaded.pkgd.min.js"></script>
        <script src="/scripts/jquery-1.11.2.min.js"></script>
        <script src="/scripts/jquery.magnific-popup.min.js"></script> */}
        <script
          type="text/javascript"
          src="https://maps.google.com/maps/api/js?language=pt&amp;key=AIzaSyC4iwZ-8pGA5EhEyQZdAWE4AG00rxfoJbg"
        ></script>
      </Helmet>

      <div className="all">
        <header>
          <div className="logo">
            <h1>AZULINA</h1>
            <h4>Azulejo artesanal</h4>
          </div>
          <nav className="menubar">
            <div>
              <ul>
                <li className="tab">
                  <Link activeClassName="active" to="/">
                    Introdução
                  </Link>
                </li>{' '}
                <li className="tab">
                  <Link activeClassName="active" to="/galeria">
                    Galeria
                  </Link>
                </li>{' '}
                <li className="tab">
                  <Link activeClassName="active" to="/contactos">
                    Contactos
                  </Link>
                </li>{' '}
              </ul>
            </div>
          </nav>
        </header>

        <div className="slideshow">
          <img src={slideImg} alt="" />
        </div>

        <div className="content">{children}</div>
      </div>
    </>
  );
};

export default Layout;
