import React from 'react';

export function withLineBreaks(text) {
  const array = [];
  for (const textPart of text.split('\n')) {
    array.push(textPart);
    array.push(<br key={Math.random()} />);
  }
  return array.slice(0, -1);
}

export function addElBetweenArrayEls(array, el) {
  return [...array]
    .map((e, i) =>
      i < array.length - 1 ? [e, React.cloneElement(el, { key: Math.random() })] : [e],
    )
    .reduce((a, b) => a.concat(b));
}

export function getOffset(element, horizontal = false) {
  if (!element) return 0;
  return (
    getOffset(element.offsetParent, horizontal) +
    (horizontal ? element.offsetLeft : element.offsetTop)
  );
}

export function encodeFormData(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export function shuffleArray(array) {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}

export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function svgAssetToDataUri(svgAsset) {
  return `data:image/svg+xml;base64,${svgAsset.childSvgContent.svgContentBase64}`;
}

export function localize(value, languages) {
  if (Array.isArray(value)) {
    return value.map(v => localize(v, languages));
  } else if (value !== null && typeof value === 'object') {
    if (/^locale_/.test(value._type)) {
      const language = languages.find(lang => value[lang]);
      return value[language];
    }

    return Object.keys(value).reduce((result, key) => {
      result[key] = localize(value[key], languages);
      return result;
    }, {});
  }
  return value;
}
