/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { localize } from '../utils';
import { DEFAULT_LANG } from '../constants';

function SEO({ langs, meta }) {
  const data = useStaticQuery(
    graphql`
      query {
        sanityMetadata {
          title {
            ...LocaleStringFragment
          }
          description {
            ...LocaleStringFragment
          }
          author
          url {
            ...LocaleUrlFragment
          }
          facebook_app_id
          image {
            asset {
              url
            }
          }
        }
      }
    `,
  );

  const metadata = localize(data.sanityMetadata, langs);

  const metaList = [
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
  ].concat(meta);

  if (metadata.title) {
    metaList.push(
      ...[
        {
          property: 'og:title',
          content: metadata.title,
        },
        {
          name: 'twitter:title',
          content: metadata.title,
        },
      ],
    );
  }

  if (metadata.description) {
    metaList.push(
      ...[
        {
          name: 'description',
          content: metadata.description,
        },
        {
          property: 'og:description',
          content: metadata.description,
        },
        {
          name: 'twitter:description',
          content: metadata.description,
        },
      ],
    );
  }

  if (metadata.url) {
    metaList.push({
      property: 'og:url',
      content: metadata.url,
    });
  }

  if (metadata.image) {
    metaList.push({
      property: 'og:image',
      content: metadata.image.asset.url,
    });
  }

  if (metadata.author) {
    metaList.push(
      ...[
        {
          name: 'author',
          content: metadata.author,
        },
        {
          name: 'twitter:creator',
          content: metadata.author,
        },
      ],
    );
  }

  if (metadata.facebook_app_id) {
    metaList.push({
      property: 'fb:app_id',
      content: metadata.facebook_app_id,
    });
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: langs[0],
      }}
      title={metadata.title}
      meta={metaList}
    />
  );
}

SEO.defaultProps = {
  langs: [DEFAULT_LANG],
  meta: [],
};

SEO.propTypes = {
  langs: PropTypes.arrayOf(PropTypes.string.isRequired),
  meta: PropTypes.arrayOf(PropTypes.object),
};

export default SEO;
